<template>
  <div class="logoBox">
    <div class="logoMain">
      <img class="logoImg" src="~assets/img/other/register/img_logo@2x.png" />
    </div>
  </div>
</template>
<script>
export default {
  name: "AuthLogo",
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.logoBox {
  position: relative;
  width: 100%;
  .logoMain {
    text-align: center;
    margin-bottom:5%;
    .logoImg {
      width: 90%;
      object-fit: cover;
      margin: auto;
    }
  }
}
</style>
